import { graphql, navigate, PageProps } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
// Components & UI
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CategoriesMembership from 'display-components/categories-membership';
import HealthCareList from 'display-components/membership-dashboard/health-care-list';
// Display Components
import QuickLinksMembership from 'display-components/quick-links-membership';

import AccountBalance from 'components/account-balance/account-balance.component';
import AutoRefillBody from 'components/auto-refill-body/auto-refill-body';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import DiscountDrugModal from 'components/discount-drug-modal/discount-drug-modal';
import MembershipLayout from 'components/layouts/membership/membership.layout';
import MembershipPlan, { MembershipPlanDetails } from 'components/membership-plan/membership-plan.component';
import PendingTelemedicinesComponent, {
    PendingTelemedicinePayload
} from 'components/pending-telemedicines/pending-telemedicines.component';
// Styles
import 'components/prescriptions-list/PrescriptionList.style.scss';

// Redux & Services
import {
    accountAcknowledgementTermsStatusSelector,
    accountAutoRefillEligibleSelector,
    accountHasInsuranceSelector,
    accountHasOutstandingBalance,
    accountIsCaliforniaUserSelector,
    accountProfileMembershipSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
// Modals
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
// Types
import {
    membershipDetailsRoutine,
    membershipGetTelehealthVisitsRoutine,
    membershipGetVisitsLinkRoutine
} from 'state/membership/membership.routines';
import {
    isMembershipAvailableSelector,
    membershipDetailSelector,
    membershipHasTelehealthVisitsSelector,
    membershipIsOnDemandSelector,
    membershipPreviousVisitsLinkSelector,
    membershipRequiresPaymentSelector,
    membershipTelehealthVisitsSelector
} from 'state/membership/membership.selector';

// eslint-disable-line no-unused-vars
import { MembershipPlanStatusType, MembershipVisitStatus, MembershipVisitUIStatus } from 'types/membership';

import { validateAutoRefillModalDisplay } from 'util/autorefill';
// Utils
import dynamicGreeting from 'util/dynamicGreeting';
import { getPhoneNumber } from 'util/globalVariables';
import storageHelper from 'util/storageHelper';
import { convertToTitleCase } from 'util/string';

const Membership: React.FC<PageProps<GatsbyTypes.MembershipDataQuery>> = ({ data }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const profileObject = useSelector(accountProfileSelector);
    const membershipObject = useSelector(membershipDetailSelector);
    const membershipDetails = useSelector(membershipDetailSelector);
    const { membershipID, hasMembership } = useSelector(accountProfileMembershipSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const previousVisitsLink = useSelector(membershipPreviousVisitsLinkSelector);
    const epostPatientNum = useSelector(accountProfilEPostPatientNumSelector);
    const isCAResident = useSelector(accountIsCaliforniaUserSelector);
    const telehealthVisitsObject = useSelector(membershipTelehealthVisitsSelector);
    const hasTelehealthVisits = useSelector(membershipHasTelehealthVisitsSelector);
    const hasOutstandingBalance = useSelector(accountHasOutstandingBalance);
    const isMembershipPaymentRequired = useSelector(membershipRequiresPaymentSelector);
    const isAutoRefillEligible = useSelector(accountAutoRefillEligibleSelector);
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);

    const [pendingTelemedicineData, setPendingTelemedicineData] = useState<PendingTelemedicinePayload>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isModalOpenRef = useRef(false);
    const isMembershipAvailable = useSelector(isMembershipAvailableSelector);
    const hasAcceptedTerms = useSelector(accountAcknowledgementTermsStatusSelector);

    useEffect(() => {
        if (!isMembershipAvailable) {
            navigate('/secure/medicine-cabinet');
        }
    }, [isMembershipAvailable]);

    useEffect(() => {
        // DRX-3209: When the user tries to go back from the SteadyMD page, some states were not updated.
        // Since the user needs the profileObject to perform any other action, we opted to listen for the 'pageshow'
        // event to handle page navigation, ensuring the page reloads instead of managing the state locally.
        // This behavior is standard in most browsers (Chrome, Mozilla Firefox, Safari), but because tab reloading
        // with JavaScript is disabled in Edge, we force the page to update here.
        // For more information on the 'pageshow' event, refer to:
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/pageshow_event

        const onPageshow = (event: Event) => {
            // Check if the event is persisted, indicating a page reload from cache,
            // or if the navigation type is back or forward.
            const persisted = (event as PageTransitionEvent).persisted;
            const navigationType = window.performance?.navigation?.type;

            if (persisted || navigationType === window.performance?.navigation?.TYPE_BACK_FORWARD) {
                // Page is loaded from cache (e.g., user navigated back)
                // Force reload to ensure it's up-to-date.
                window.location.reload();
            }
        };

        // Add event listener for 'pageshow' event
        window.addEventListener('pageshow', onPageshow);

        // Clean up event listener to prevent memory leaks
        return () => {
            window.removeEventListener('pageshow', onPageshow);
        };
    }, []);

    useEffect(() => {
        const memberVisits: PendingTelemedicinePayload['payload'] =
            telehealthVisitsObject?.Members?.filter((member) => {
                return member?.TelehealthVisit?.length > 0;
            }).flatMap((member) => {
                return member.TelehealthVisit?.map((visit) => {
                    return {
                        patientName: member.PatientFirstName + ' ' + member.PatientLastName,
                        specialty: visit.ProgramName,
                        date: visit.AppointmentStartsAt || visit.CreatedAt,
                        status: visit.NewStatus as MembershipVisitStatus,
                        uiStatus: visit.StatusUI as MembershipVisitUIStatus,
                        showPatientName: membershipDetails && membershipDetails.members.length > 1 ? true : false,
                        epostPatientNum: visit.EpostPatientNum
                    };
                });
            }) || [];

        const userVisits: PendingTelemedicinePayload['payload'] =
            telehealthVisitsObject?.TelehealthVisit?.map((visit) => {
                return {
                    patientName: telehealthVisitsObject.PatientFirstName + ' ' + telehealthVisitsObject.PatientLastName,
                    specialty: visit.ProgramName,
                    date: visit.AppointmentStartsAt || visit.CreatedAt,
                    status: visit.NewStatus as MembershipVisitStatus,
                    uiStatus: visit.StatusUI as MembershipVisitUIStatus,
                    showPatientName: membershipDetails && membershipDetails.members.length > 1 ? true : false,
                    epostPatientNum: visit.EpostPatientNum
                };
            }) || [];

        const visits = [...userVisits, ...memberVisits].sort((a, b) => {
            return new Date(String(b.date)).getTime() - new Date(String(a.date)).getTime();
        });

        const pendingTelemedicines: PendingTelemedicinePayload = {
            payload: visits
        };

        setPendingTelemedicineData(pendingTelemedicines);
    }, [membershipDetails, membershipObject, telehealthVisitsObject]);

    const closeModalHandler = () => {
        dispatch(closeModal({}));
        storageHelper.local.setAutoRefillToggleFlag();
    };

    // DRX-2534: To prevent duplication of components and queries, the DrugDiscountModal now directly calls the specific query inside the component.
    // This approach allows us to reuse the existing component and function responsible for opening the modal.
    useEffect(() => {
        if (!isModalOpen) {
            setIsModalOpen(isModalOpenRef.current);
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (profileObject !== undefined) {
            dispatch(membershipGetVisitsLinkRoutine.trigger({ epostPatientNumber: epostPatientNum }));
        }
    }, [dispatch, membershipID, profileObject, membershipDetails, epostPatientNum]);

    useEffect(() => {
        if (!membershipObject && membershipID) {
            dispatch(membershipDetailsRoutine.trigger(membershipID));
        }
    }, [dispatch, membershipID, membershipObject]);

    useEffect(() => {
        if (epostPatientNum && !telehealthVisitsObject) {
            dispatch(membershipGetTelehealthVisitsRoutine.trigger({ epostPatientNumber: epostPatientNum }));
        }
    }, [dispatch, epostPatientNum, telehealthVisitsObject]);

    useEffect(() => {
        // Validating that we have already loaded membership details information.
        if (hasAcceptedTerms && membershipDetails !== undefined) {
            if (validateAutoRefillModalDisplay(isAutoRefillEligible, isCAResident)) {
                dispatch(
                    openModal({
                        showClose: true,
                        className: 'scroll-modal',
                        onClose: closeModalHandler,
                        bodyContent: (
                            <BirdiModalContent
                                icon={'none'}
                                title={t(`pages.autoRefill.userVerification.title`)}
                                body={<AutoRefillBody isModal onClose={closeModalHandler} />}
                            />
                        ),
                        ctas: []
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, membershipDetails, isAutoRefillEligible, isCAResident, hasAcceptedTerms]);

    const planDetails: MembershipPlanDetails = {
        planName: membershipObject?.planName as string,
        planStatus: membershipObject?.planStatus as unknown as MembershipPlanStatusType,
        prescriptionsLimit: membershipObject?.prescriptionsLimit as number,
        prescriptionsMade: membershipObject?.prescriptionsMade as number,
        virtualVisitsLimit: membershipObject?.virtualVisitsLimit as number,
        virtualVisitsMade: membershipObject?.virtualVisitsMade as number
    };

    const userNameUppercase = profileObject ? profileObject.patientFirstName : '';
    const userName = convertToTitleCase(userNameUppercase);

    const isMembershipOwner = membershipDetails?.isPlanOwner
        ? 'pages.membership.healthCare.paymentDue.ownerPlanPaymentDue'
        : 'pages.membership.healthCare.paymentDue.memberPlanPaymentDue';

    const { payload } = pendingTelemedicineData || {};
    const hasPendingTelemedicineData = hasTelehealthVisits && payload && payload.length > 0;

    const handlePaymentDue = (e: any) => {
        if (e?.target.className === 'link') return;

        if (isMembershipPaymentRequired) {
            dispatch(
                openModal({
                    showClose: false,
                    size: 'lg',
                    type: 'danger',
                    contentClassName: 'modal-content-padding',
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.membership.healthCare.paymentDue.title')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t(isMembershipOwner)}
                            note={t('pages.membership.healthCare.paymentDue.note', {
                                phoneNumber: getPhoneNumber({ isEnd: true })
                            })}
                        />
                    ),
                    ctas: [
                        {
                            label: t('pages.membership.healthCare.paymentDue.button'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            }
                        }
                    ]
                })
            );
        } else {
            navigate('/secure/membership/request-visit');
        }
    };

    const handleBirdiSelectModal = () => {
        isModalOpenRef.current = isModalOpen;
        setIsModalOpen(!isModalOpen);
    };

    const title = React.useCallback((): string => {
        return dynamicGreeting(
            t('pages.membership.labels.title.morning'),
            t('pages.membership.labels.title.afternoon'),
            t('pages.membership.labels.title.evening')
        );
    }, [t]);

    const greetingMessage = profileObject ? `${title()}, ${userName}.` : `${title()}.`;

    if (!isMembershipAvailable) {
        return null;
    }

    return (
        <MembershipLayout greetingMessage={greetingMessage}>
            <div className="membership-telemedicine">
                <MembershipPlan {...planDetails} />
                {hasOutstandingBalance && (
                    <div className="membership-account-balance">
                        <AccountBalance variant="banner" />
                    </div>
                )}
                {hasPendingTelemedicineData && <PendingTelemedicinesComponent {...pendingTelemedicineData} />}
                <HealthCareList handleRequestTelemedicine={handlePaymentDue}>
                    <CategoriesMembership handleCardClick={handlePaymentDue} />
                </HealthCareList>
                <QuickLinksMembership prescriptionModal={handleBirdiSelectModal} previousVisits={previousVisitsLink} />
                <DiscountDrugModal
                    accountHasInsurance={accountHasInsurance}
                    isModalOpen={isModalOpen}
                    location="Membership"
                    isMembershipUser={hasMembership}
                    isOnDemandPlan={isOnDemandPlan}
                />
            </div>
        </MembershipLayout>
    );
};

export default Membership;

export const query = graphql`
    query MembershipData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        successModalPillImage: file(relativePath: { eq: "assets/images/hero-pill-image-sample.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
