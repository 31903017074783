import { useCallback, useRef, useState } from 'react';

import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';

import './category-card.style.scss';

interface CategoryCardProps {
    title: string;
    bodyText: string;
    image: string;
    list: string[];
    icon: string;
    conditions: string;
    handleClick: Function;
    hasScroll?: boolean;
}

const CategoryCard = ({
    title,
    bodyText,
    list,
    image,
    icon,
    conditions,
    handleClick,
    hasScroll = true
}: CategoryCardProps) => {
    // click and drag effect
    // inspired by: https://codepen.io/thenutz/pen/VwYeYEE
    const sliderRef = useRef(null);
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = useCallback((e) => {
        setIsDown(true);
        sliderRef.current.classList.add('active');
        setStartX(e.pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsDown(false);
        sliderRef.current.classList.remove('active');
    }, []);

    const handleMouseUp = useCallback(() => {
        setIsDown(false);
        sliderRef.current.classList.remove('active');
    }, []);

    const handleMouseMove = useCallback(
        (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - sliderRef.current.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            sliderRef.current.scrollLeft = scrollLeft - walk;
        },
        [isDown, startX, scrollLeft]
    );

    return (
        <button
            className={`category category__block__card ${isDown ? 'active' : ''}`}
            onClick={() => handleClick()}
            onKeyPress={() => {}}
            role="link"
            type="button"
            aria-label={title}
            tabIndex={0}
        >
            <img className="category__banner" src={image} alt={title} draggable={false} />
            <div className="category__icon">
                <img draggable={false} src={icon} alt={title} />
            </div>
            <div className="category__info">
                <div className="category__info__content">
                    <div>
                        <h5 className="category__info__content__title">{title}</h5>
                        <div className="category__info__content__section">
                            <p className="category__info__content__section__bodyText">{bodyText}</p>
                            <ChevronIcon direction="right" />
                        </div>
                    </div>
                </div>
                <div className="category__info__treatment">{conditions}</div>
            </div>
            <div
                className="category__list"
                ref={sliderRef}
                role="presentation"
                // optional scroll functionality
                onMouseDown={hasScroll ? handleMouseDown : undefined}
                onMouseLeave={hasScroll ? handleMouseLeave : undefined}
                onMouseUp={hasScroll ? handleMouseUp : undefined}
                onMouseMove={hasScroll ? handleMouseMove : undefined}
            >
                {list.map((item, idx) => (
                    <div className="category__list__content__item" key={idx}>
                        {item}
                    </div>
                ))}
            </div>
        </button>
    );
};

export default CategoryCard;
