import HealthConditionMen from 'assets/icons/health-condition-men.svg';
import HealthConditionMental from 'assets/icons/health-condition-mental-2.svg';
import HealthConditionRxRefills from 'assets/icons/health-condition-rxrefills.svg';
import HealthConditionSkinCare from 'assets/icons/health-condition-skincare-2.svg';
import HealthConditionThroat from 'assets/icons/health-condition-throat.svg';
import HealthConditionWomen from 'assets/icons/health-condition-women.svg';
import MenHealth from 'assets/images/men-health.png';
import MentalHealth from 'assets/images/mental-health.png';
import Refills from 'assets/images/refills.png';
import Skincare from 'assets/images/skincare.png';
import UrgentCare from 'assets/images/urgent-care.png';
import WomenHealth from 'assets/images/women-health.png';
import { useTranslation } from 'react-i18next';

// Components
import CategoryCard from 'components/category-card';

const Categories = {
    refills: ['Anxiety', 'Depression', 'Diabetes', 'High Blood Pressure', 'High Cholesterol', 'Asthma'],
    urgentCare: [
        'UTI',
        'Pink Eye',
        'Cough, Cold, Flu',
        'COVID: SARS-CoV-2',
        'Bacterial Sinusitis',
        'Allergies',
        'Strep',
        'Sore Throat',
        'Yeast Infection',
        'Motion Sickness',
        'Bacterial Vaginosis',
        'General Urgent Care'
    ],
    menHealth: ['ED', 'Hair Loss', 'Hair Thinning', 'Gout'],
    womenHealth: ['UTI', 'Birth Control', 'Yeast Infection', 'Hair Thinning', 'Eyelash Growth', 'Bacterial Vaginosis'],
    skincare: ['Anti-aging', 'Acne', 'Eczema', 'Cold Sore', 'Eyelash Growth'],
    mentalHealth: ['Insomnia', 'Smoking Cessation']
};

export type CategoriesMembershipProps = {
    handleCardClick: Function;
};

const CategoriesMembership: React.FC<CategoriesMembershipProps> = ({ handleCardClick }) => {
    const { t } = useTranslation();

    return (
        <>
            <CategoryCard
                icon={HealthConditionRxRefills}
                image={Refills}
                list={Categories.refills}
                title={t('pages.membership.dashboardCategories.refills.title')}
                bodyText={t('pages.membership.dashboardCategories.refills.body')}
                conditions={t('pages.membership.dashboardCategories.numberOfConditionsTreatment', {
                    conditions: Categories.refills.length
                })}
                handleClick={handleCardClick}
            />
            <CategoryCard
                icon={HealthConditionThroat}
                image={UrgentCare}
                list={Categories.urgentCare}
                title={t('pages.membership.dashboardCategories.urgentCare.title')}
                bodyText={t('pages.membership.dashboardCategories.urgentCare.body')}
                conditions={t('pages.membership.dashboardCategories.numberOfConditionsTreatment', {
                    conditions: Categories.urgentCare.length
                })}
                handleClick={handleCardClick}
            />
            <CategoryCard
                icon={HealthConditionMen}
                image={MenHealth}
                list={Categories.menHealth}
                title={t('pages.membership.dashboardCategories.menHealth.title')}
                bodyText={t('pages.membership.dashboardCategories.menHealth.body')}
                conditions={t('pages.membership.dashboardCategories.numberOfConditionsTreatment', {
                    conditions: Categories.menHealth.length
                })}
                handleClick={handleCardClick}
                hasScroll={false}
            />
            <CategoryCard
                icon={HealthConditionWomen}
                image={WomenHealth}
                list={Categories.womenHealth}
                title={t('pages.membership.dashboardCategories.womenHealth.title')}
                bodyText={t('pages.membership.dashboardCategories.womenHealth.body')}
                conditions={t('pages.membership.dashboardCategories.numberOfConditionsTreatment', {
                    conditions: Categories.womenHealth.length
                })}
                handleClick={handleCardClick}
            />
            <CategoryCard
                icon={HealthConditionSkinCare}
                image={Skincare}
                list={Categories.skincare}
                title={t('pages.membership.dashboardCategories.skincare.title')}
                bodyText={t('pages.membership.dashboardCategories.skincare.body')}
                conditions={t('pages.membership.dashboardCategories.numberOfConditionsTreatment', {
                    conditions: Categories.skincare.length
                })}
                handleClick={handleCardClick}
            />
            <CategoryCard
                icon={HealthConditionMental}
                image={MentalHealth}
                list={Categories.mentalHealth}
                title={t('pages.membership.dashboardCategories.mentalHealth.title')}
                bodyText={t('pages.membership.dashboardCategories.mentalHealth.body')}
                conditions={t('pages.membership.dashboardCategories.numberOfConditionsTreatment', {
                    conditions: Categories.mentalHealth.length
                })}
                handleClick={handleCardClick}
                hasScroll={false}
            />
        </>
    );
};

export default CategoriesMembership;
