import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import DollarIcon from 'ui-kit/icons/dollar-icon/dollar-icon';
import InfoBox from 'ui-kit/info-box/info-box';
import PageSection from 'ui-kit/page-section/page-section';

import { accountProfileSelector } from 'state/account/account.selectors';

import { formatPrice } from 'schema/price.schema';

import { AccountBalanceProps } from './account-balance.props';
import './account-balance.style.scss';
import PayBalanceForm from './pay-balance-form';

const AccountBalance = ({ variant, onPaymentSuccess }: AccountBalanceProps): ReactElement => {
    const { t } = useTranslation();
    const profileObject = useSelector(accountProfileSelector);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (profileObject?.patientBalance) {
            setCurrentBalance(Number.parseFloat(profileObject.patientBalance));
        } else {
            setCurrentBalance(0);
        }
    }, [profileObject, setCurrentBalance]);

    const toggleForm = () => {
        setShowForm(!showForm);
    };

    if (currentBalance > 0) {
        switch (variant) {
            case 'form': {
                return (
                    <div className="account-balance">
                        <div className="profile-active-menu-item-wrapper mb-5">
                            <div className="profile-active-menu-item-top-wrapper">
                                <Container fluid>
                                    <Row>
                                        <Col className="d-flex flex-column">
                                            <h2 className="h3 profile-active-menu-heading">
                                                {t(`components.accountBalance.form.heading`)}
                                            </h2>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className="profile-active-menu-item-bottom-wrapper px-0">
                                <div className="px-2">
                                    <PageSection className="px-md-5 mb-0">
                                        <>
                                            <Row>
                                                <Col>
                                                    <div className="h3">
                                                        {t(`components.accountBalance.form.balanceDue`, {
                                                            balance: formatPrice(currentBalance)
                                                        })}
                                                    </div>
                                                    <p className="text-slate mb-4">
                                                        {t(`components.accountBalance.form.outstandingBalance`)}
                                                    </p>
                                                    <div>
                                                        <Button
                                                            type={'button'}
                                                            className={`mt-2 ${showForm ? 'd-none' : ''}`}
                                                            label={t('components.accountBalance.form.labels.payNow')}
                                                            onClick={toggleForm}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    </PageSection>
                                </div>
                                <Row className="collapsable">
                                    <Col className={showForm ? 'h-auto' : 'h-0'}>
                                        <div className="border-top border-geyser mt-2">
                                            <div className="px-2">
                                                <PageSection className="px-md-5 pt-4 mb-0">
                                                    <div>
                                                        <PayBalanceForm
                                                            key={currentBalance}
                                                            currentBalance={currentBalance}
                                                            submissionCallback={toggleForm}
                                                            cancelCallback={toggleForm}
                                                            onPaymentSuccess={onPaymentSuccess}
                                                        />
                                                    </div>
                                                </PageSection>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                );
            }
            case 'banner': {
                return (
                    <Container className="px-0">
                        <div className="account-balance-banner">
                            <InfoBox
                                icon={<DollarIcon />}
                                buttonPosition="right"
                                button={
                                    <Button
                                        className="my-md-0 sm-full btn-bold btn-outline"
                                        label={t('components.accountBalance.banner.labels.payNow')}
                                        onClick={() => navigate('/secure/profile/payment/history')}
                                        type="button"
                                        dataGALocation="MedicineCabinet"
                                        variant="outline-primary"
                                    />
                                }
                            >
                                <p>
                                    {t(`components.accountBalance.banner.outstandingBalance.beforeAmount`)}
                                    <span className="balanceAmount font-weight-bold">
                                        {formatPrice(currentBalance)}
                                    </span>
                                    {t(`components.accountBalance.banner.outstandingBalance.afterAmount`)}
                                </p>
                            </InfoBox>
                        </div>
                    </Container>
                );
            }
        }
    }
    return <></>;
};

export default AccountBalance;
