import * as yup from 'yup';

export const PAY_BALANCE_SCHEMA = yup.object().shape({
    paymentAmount: yup
        .string()
        .required('Required')
        .test('Payment Amount', 'BelowMin', function (value) {
            if (!value) return false;
            return Number(value) > 0;
        })
        .test('Payment Amount', 'AboveMax', function (value) {
            if (this.parent.currentBalance && value) {
                return value <= this.parent.currentBalance;
            }
            return false;
        }),
    cardSeqNum: yup.string().required()
});
